import React, {useEffect, useMemo} from 'react';
import { Tabs, message, Button } from "antd";
import Once from "../../components/Auth/Once/Once";
import {esiaLogin, get_user_actions} from "../../store/actions/userAction";
import LineLoader from "../../components/LineLoader/LineLoader";
import AuthorizedModal from "../../components/AuthorizedModal/AuthorizedModal";
import {Errors} from "../../store/slices/userSlice";
import Anytime from '../../components/Auth/Anytime/AnyTime';
import {useAppDispatch, useAppSelector} from "../../store";
import styles from './Auth.module.css'
import {useLocation} from "react-router-dom";

const Auth = () => {
  const {_IsLoading, _isModal} = useAppSelector(({userSlice}) => userSlice);
  const {TabPane} = Tabs;
  const dispatch = useAppDispatch();
  const token = localStorage.getItem('tokenAuth');
  const tokenS3 = localStorage.getItem('tokenS3');

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');
  const state = searchParams.get('state');

  token && window.unione.setUnioneToken(token)
    .catch((error) => {
      console.error('Error:', error)
    });

  tokenS3 && window.unione.setS3Token(tokenS3)
    .catch((error) => {
      console.error('Error:', error)
    });

  useEffect(() => {
    dispatch(get_user_actions(token || ''));

    message.config({
      top: 85,
    });
  }, [])

  useEffect(() => {
    if (!token && code && state) {
      dispatch(esiaLogin({ code, state }));
    }
  }, [token, code, state, dispatch]);

  const handleChangeTab = () => {
    dispatch(Errors(['']));
  }

  const adaptiveSize = useMemo(() => {
    if (window.innerWidth < 500) {
      return 'small'
    } else {
      return 'large'
    }
  }, [window.innerWidth])

  return (
    <>
      {_IsLoading
        ? <LineLoader />
        : (
          <div className={styles.auth}>
            <h1 className={styles.authTitle}>Вход в UniOne</h1>
            <Tabs centered size={adaptiveSize} onChange={handleChangeTab}>
              <TabPane tab="По коду" key="1">
                <Once />
              </TabPane>
              <TabPane tab="По паролю" key="2">
                <Anytime />
              </TabPane>
            </Tabs>
            <Button
              className={styles.link}
              type="link"
              href={process.env.REACT_APP_PRIVACY_POLICY}
              target="_blank"
            >
              Политика обработки персональных данных
            </Button>
          </div>
        )
      }
      {
        _isModal ?
          <AuthorizedModal />
          :
          null
      }
    </>
  );
};

export default Auth;
