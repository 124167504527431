import React, {FC, useEffect} from 'react';
import {Button} from "antd";
import {useNavigate} from "react-router-dom";
import styles from "./NotFound.module.css";
import {useAppDispatch} from "../../store";
import {IsErrorPage} from "../../store/slices/userSlice";

export const NotFound: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(IsErrorPage(true))
    return () => {
      dispatch(IsErrorPage(false))
    }
  }, [])

  return (
    <div className={styles.block}>
      <h1 className={styles.title}>404</h1>
      <h3 className={styles.title__text}>Ошибочка вышла</h3>
      <p className={styles.description}>
        Данная страница устарела или просто не существует,<br/> перейдите на гланую по ссылке ниже
      </p>
      <Button
        className={styles.btn}
        type="primary"
        onClick={() => navigate("/login")}
      >
        На главную
      </Button>
    </div>
  );
};
