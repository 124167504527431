import React, {FC, useCallback, useEffect, useState} from 'react';
import Text from "antd/es/typography/Text";
import {Button} from "antd";
import {repeatFunc} from "../../store/actions/userAction";
import styles from './codeRequest.module.css'
import { ICounter } from '../../types/types';
import {useAppDispatch, useAppSelector} from "../../store";
import {InvisibleSmartCaptcha} from "@yandex/smart-captcha";
import cn from 'classnames';

const Counter: FC<ICounter> = ({counter, setCounter, telegram, email}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [token, setToken] = useState<string>('');
  const { new_user } = useAppSelector(({ userSlice }) => userSlice)

  const dispatch = useAppDispatch()
  const { _userPhone, _userEmail } = useAppSelector(({userSlice}) => userSlice)

  const handleChallengeHidden = useCallback(() => setVisible(false), []);
  const handleButtonClick = () => {
    if (token !== '') {
      sendSmsConfirm()
    }
    setVisible(true);
  }

  const padTime = (time: number): string => {
    return String(time).length === 1 ? `0${time}` : `${time}`;
  };

  const format = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${padTime(seconds)}`;
  };

  useEffect(() => {
    if (token !== '') {
      sendSmsConfirm()
    }
  }, [token])

  useEffect(() => {
    let timer: number;
    if (counter > 0) {
      timer = window.setTimeout(() => setCounter(counter - 1), 1000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [counter]);

  const sendSmsConfirm = () => {
    setCounter(90);
    let send_to
    if (telegram && !email) {
      send_to = 'email'
    } else if (new_user) {
      send_to = 'email'
    } else {
      send_to = 'phone'
    }
    dispatch(repeatFunc({phone: _userPhone, email: _userEmail, send_to, verify_token: token}))
  }

  const btnTheme = cn(styles.resendBtn, {[styles.resendBtnActive]: counter === 0})

  return (
    <div>
      <Text type="secondary">
        <Button
          type='link'
          disabled={counter !== 0}
          className={btnTheme}
          onClick={handleButtonClick}
        >
          Выслать повторно
        </Button>
        {
          !!counter && (
            `через ${format(counter)} секунд`
          )
        }
      </Text>

      <InvisibleSmartCaptcha
        key={visible ? 'captchaVisible' : 'captchaHidden'}
        sitekey={`${process.env.REACT_APP_CLIENT_KEY}`}
        onSuccess={setToken}
        onChallengeHidden={handleChallengeHidden}
        visible={visible}
      />
    </div>
  );
};

export default Counter;
