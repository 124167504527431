import React, { FC, useEffect, useState } from "react";
import { Button, Modal } from "antd";
import styles from "./codeRequest.module.css";
import Counter from "./Counter";
import { _confirm_code } from "../../store/actions/userAction";
import { PinInput } from "react-input-pin-code";
import {
  Auth_Confirm,
  EmailPut,
  Errors,
  NewUser,
  SetUserBlock,
} from "../../store/slices/userSlice";
import { useAppDispatch, useAppSelector } from "../../store";
import { CloseIcon } from "../../assets/icons/CloseIcon";
import Icon from "@ant-design/icons";
import { AcronymEnum } from "../../types/types";
import {
  PinContainerStyle,
  PinInputStyle,
  pastelBlue,
  brightRed,
  greenRgbColor,
  regexObj,
} from "../../utils";
import { UserBlock } from "../../components/UserBlock/UserBlock";

const CodeRequest: FC = () => {
  const [values, setValues] = useState(new Array(6).fill(""));
  const [counter, setCounter] = useState<number>(90);
  const {
    _phone,
    email,
    _userEmail,
    _userPhone,
    telegram,
    acronym = AcronymEnum.rf,
    block,
  } = useAppSelector(({ userSlice }) => userSlice);
  const [maskPhone, setMaskPhone] = useState<string>("");
  const dispatch = useAppDispatch();

  const goBack = () => {
    dispatch(Errors([""]));
    dispatch(Auth_Confirm(false));
    dispatch(EmailPut(""));
    dispatch(NewUser(false));
  };

  const confirmFunc = () => {
    const code = values.join("");
    setValues(new Array(6).fill(""));
    dispatch(
      _confirm_code({
        phone: _userPhone || _phone,
        code,
        email,
        _userEmail,
        telegram,
      })
    );
  };

  useEffect(() => {
    if (_phone.includes("*")) {
      const transformedPhone = `+7 9${_phone.slice(3, 6)} ${_phone.slice(
        6,
        9
      )} ${_phone.slice(9, 11)}`;
      setMaskPhone(transformedPhone);
    } else {
      setMaskPhone(
        _phone.replace(
          regexObj[acronym].regex,
          regexObj[acronym].regexTransform
        )
      );
    }
  }, [_phone]);

  const btnDisable = () => {
    return values.join("").length <= 3;
  };

  const hideBlockModal = () => {
    dispatch(SetUserBlock(false));
  };

  return (
    <div className={styles._container}>
      <div className={styles._body}>
        <Icon
          component={CloseIcon}
          className={styles._close}
          onClick={goBack}
        />
        <h1 className={styles._title_text}>Войти в UniOne</h1>
        <div className={styles._text}>
          {email ? (
            <p className={styles._modalText}>
              Мы отправили код подтверждения на ваш email{" "}
              <span className={styles._phone}> {email} </span>
            </p>
          ) : (
            <p className={styles._modalText}>
              На ваш {telegram ? "telegram" : "телефон"}{" "}
              <span className={styles._phone}>
                {" "}
                {telegram ? "" : maskPhone || _phone}{" "}
              </span>{" "}
              отправлено текстовое сообщение с кодом, введите его в поле, чтобы
              продолжить.
            </p>
          )}
        </div>

        <PinInput
          containerStyle={PinContainerStyle}
          inputStyle={PinInputStyle}
          autoFocus={true}
          placeholder={""}
          values={values}
          onChange={(value, index, values) => setValues(values)}
          validBorderColor={greenRgbColor}
          focusBorderColor={pastelBlue}
          errorBorderColor={brightRed}
        />

        <Modal open={block} centered footer={null} onCancel={hideBlockModal}>
          <UserBlock />
        </Modal>

        <div className={styles.resend}>
          <Counter
            counter={counter}
            setCounter={setCounter}
            telegram={telegram}
            email={email}
          />
        </div>

        <Button
          disabled={btnDisable()}
          className={styles.sendButton}
          type="primary"
          onClick={confirmFunc}
        >
          Отправить
        </Button>
      </div>
    </div>
  );
};

export default CodeRequest;
