import React, {FC, useEffect} from 'react';
import {logout} from "../../store/actions/userAction";
import Loading from '../../components/Loading'
import {useAppDispatch, useAppSelector} from "../../store";

const Logout: FC = () => {
    const {_IsLoading} = useAppSelector(({userSlice}) => userSlice)
    const dispatch = useAppDispatch()
    const token = localStorage.getItem('tokenAuth')

    useEffect(() => {
        dispatch(logout(token || ''))
    }, [])

    return (
        <div>
            {_IsLoading
                ? <Loading/>
                : <h1>Вы вышли из системы</h1>}
        </div>
    );
};

export default Logout;