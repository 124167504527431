import React, {FC, useEffect} from 'react';
import Main from "../pages/Main";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Auth from '../pages/Auth/Auth';
import {Params_url, Url_Put} from "../store/slices/userSlice";
import Logout from "../pages/Logout/Logout";
import Recovery from "../pages/Recovery/Recovery";
import {useAppDispatch, useAppSelector} from "../store";
import CodeRequest from "../pages/codeRequest/CodeRequest";
import {Agreement} from "../pages/Agreement/Agreement";
import {BadGateway} from "../pages/BadGateway";
import {ServiceUpdate} from "../pages/ServiceUpdate";
import {NotFound} from "../pages/NotFound/NotFound";
import {message} from "antd";
import {getCookie, getDomain} from "../utils";
import {SuccessIcon} from "../assets/icons/SuccessIcon";

const App: FC = () => {
  const {_auth, badGateway} = useAppSelector(({userSlice}) => userSlice)
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const location = useLocation()
  const param = location.search
  const Search_Url = () => {
    let dict: any = {}
    location.search
      .substring(1)
      .split('&')
      .forEach((item) => {
        let param = item.split('=')
        dict[param[0]] = param[1]
      })
    dispatch(Url_Put(dict))
  }

  useEffect(() => {
    const err_bind = getCookie('err_bind')
    if (err_bind) {
      message.success({
        className: 'messageSuccess',
        content: 'Авторизуйтесь по номеру телефона и нажмите в профиле на кнопку "Привязать" возле блока LeaderID',
        icon: <SuccessIcon/>,
      });
      document.cookie = `err_bind=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${getDomain}; path=/;`;
    }
  }, [])

  useEffect(() => {
    Search_Url()
    dispatch(Params_url(param))
    if (badGateway) {
      navigate('/techmaintenance')
    } else if (location.pathname !== '/logout' && !location.pathname.includes('/rec')) {
      navigate(_auth ? `/confirm${param}` : `/login${param}`)
    }
  }, [_auth, badGateway])

  return (
    <Routes>
      <Route path={'/'} element={<Main/>}>
        <Route path={'login'} element={<Auth/>}/>
        <Route path={'logout'} element={<Logout/>}/>
        <Route path={'confirm'} element={<CodeRequest/>}/>
        <Route path={'rec'} element={<Recovery/>}/>
        <Route path={'soglasie_pd'} element={<Agreement/>}/>
        <Route path={'techmaintenance'} element={<ServiceUpdate/>}/>
        <Route path={'502'} element={<BadGateway/>}/>
        <Route path={'*'} element={<NotFound/>}/>
      </Route>
    </Routes>
  );
};

export default App;
