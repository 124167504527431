import React, { FC, useState } from 'react';
import { Button, Form, Input, Modal, Select } from "antd";
import { phoneValid } from "../../utils";
import MaskedInput from "antd-mask-input";
import Icon from "@ant-design/icons";
import { Drots } from "../../assets/icons/Drots";
import { useAppSelector } from "../../store";
import { IRecoverModal } from "../../types/types";
import styles from "./PhoneRecoveryModal.module.css";
import { arm, blr, chn, kaz, kgz, mng, rus, uzb } from '../../assets/flags';

const PhoneRecoveryModal: FC<IRecoverModal> = ({
  isForgotPasswordModalVisible,
  setIsForgotPasswordModalVisible,
  handleChange,
  maskRecovery,
  phoneRecovery,
  setPhoneRecovery,
  onRecoveryAccess,
  formModal
}) => {
  const { _load_button, acronym } = useAppSelector(({userSlice}) => userSlice)
  const [ emailValue, setEmailValue ] = useState("");

  const selectOptions = (
    <>
      <Select.Option value='{"code": "+7", "acronym": "RF"}'><img src={rus} alt="" width={20}/> +7</Select.Option>
      <Select.Option value='{"code": "+7", "acronym": "KZ"}'><img src={kaz} alt="" width={20}/> +7</Select.Option>
      <Select.Option value='{"code": "+375", "acronym": "BY"}'><img src={blr} alt="" width={20}/> +375</Select.Option>
      <Select.Option value='{"code": "+996", "acronym": "KG"}'><img src={kgz} alt="" width={20}/> +996</Select.Option>
      <Select.Option value='{"code": "+998", "acronym": "UZ"}'><img src={uzb} alt="" width={20}/> +998</Select.Option>
      <Select.Option value='{"code": "+374", "acronym": "AM"}'><img src={arm} alt="" width={20}/> +374</Select.Option>
      <Select.Option value='{"code": "+976", "acronym": "MN"}'><img src={mng} alt="" width={20}/> +976</Select.Option>
      <Select.Option value='{"code": "+86", "acronym": "CN"}'><img src={chn} alt="" width={20}/> +86</Select.Option>
    </>
  );


  return (
    <Modal
      className={styles.modalForgotPassword}
      open={isForgotPasswordModalVisible}
      closable={false}
      width={576}
      onCancel={() => setIsForgotPasswordModalVisible(false)}
      footer={[
        <Button
          className={styles.modalBtnLink}
          type="link"
          key="submit"
          loading={_load_button}
          onClick={() => setIsForgotPasswordModalVisible(false)}
        >
          Войти в систему
        </Button>,
      ]}
    >
      <h2 className={styles.modalTitle}>Забыли пароль?</h2>
      <p className={styles.modalText}>
        Для восстановления доступа введите номер телефона
      </p>
      <p className={`${styles.modalText} ${styles.modalGrey}`}>
        Ссылка для восстановления доступа будет отправлена на вашу электронную
        почту, указанную в профиле
      </p>
      <Form form={formModal} layout="vertical">
        <Form.Item
          name="phoneRecovery"
          className={styles.formBlock}
          rules={[
            {required: true, message: "введите номер телефона", min: 4},
            {
              validator: (_, value) =>
                value &&
                phoneValid.test(value)
                  ? Promise.resolve("okay")
                  : Promise.reject(""),
            },
          ]}
          hasFeedback
        >
          <Input.Group className={styles.inpGroup}>
            <Select
              defaultValue='{"code": "+7", "acronym": "RF"}'
              onChange={handleChange}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
            >
              {selectOptions}
            </Select>
            <span className={styles.line} />
            {acronym === 'EM'
              ? <Input
                value={emailValue}
                size="middle"
                type="text"
                allowClear
                placeholder="Введите почту"
                onChange={(e) => setEmailValue(e.target.value)}
              />
              : <MaskedInput
                mask={maskRecovery}
                size="middle"
                allowClear
                name="phoneRecovery"
                type="text"
                placeholder={maskRecovery}
                value={phoneRecovery}
                onChange={(e) => setPhoneRecovery(e.target.value)}
              />
            }

            <Icon component={Drots} className={styles.drots} />
          </Input.Group>
        </Form.Item>
        <Form.Item shouldUpdate>
          {() => (
            <Button
              type="primary"
              block
              className={styles.buttonLogin}
              loading={_load_button}
              onClick={onRecoveryAccess}
              disabled={!phoneRecovery || phoneRecovery?.includes('_')}
            >
              Отправить
            </Button>
          )}
        </Form.Item>
      </Form>
      <p className={styles.modalLine}>или</p>
    </Modal>
  );
};

export default PhoneRecoveryModal;