import React, {FC} from 'react';
import {Row, Spin} from "antd";

const Index: FC = () => {
  return (
    <Row className='app' justify={"center"} align='middle'>
      <Spin size='large'/>
    </Row>
  )

};

export default Index;