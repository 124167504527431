import React from "react";

export const Block = () => (
  <svg width="84" height="86" viewBox="0 0 84 86" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_2689_34586)">
      <path d="M32.0003 73.3333H52.0003C68.667 73.3333 75.3337 66.6667 75.3337 50V30C75.3337 13.3333 68.667 6.66666 52.0003 6.66666H32.0003C15.3337 6.66666 8.66699 13.3333 8.66699 30V50C8.66699 66.6667 15.3337 73.3333 32.0003 73.3333Z" fill="#EB5757" />
      <path d="M32.5664 49.4331L51.4331 30.5664" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M51.4331 49.4331L32.5664 30.5664" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <filter id="filter0_d_2689_34586" x="-6" y="-4" width="96" height="96" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.964706 0 0 0 0 0.407843 0 0 0 0 0.329412 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2689_34586" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2689_34586" result="shape" />
      </filter>
    </defs>
  </svg>
)