import { CountryCodes } from "./PhoneMask_enum";

export const getMask = (code: string) => {
    switch (code) {
        case CountryCodes.RF: return "(000)000-00-00";
        case CountryCodes.KG: return "(000)00-00-00";
        case CountryCodes.KZ: return "(000)000-00-00";
        case CountryCodes.BY: return "(00)000-00-00";
        case CountryCodes.UZ: return "(00)000-00-00";
        case CountryCodes.AM: return "(000)000-00-00";
        case CountryCodes.MN: return "(000)000-0000";
        case CountryCodes.CN: return "(000)0000-0000";
        default: return "(000)000-00-00";
    }
}