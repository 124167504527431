import React, { FC, useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import Text from "antd/es/typography/Text";
import styles from "./Once.module.css";
import { emailValid, phoneRegions, phoneRules, phoneValid } from "../../../utils";
import { onceFunc } from "../../../store/actions/userAction";
import Icon, { WarningOutlined } from "@ant-design/icons";
import { Auth_Confirm, SetUserBlock } from "../../../store/slices/userSlice";
import { useAppDispatch, useAppSelector } from "../../../store";
import MaskedInput from "antd-mask-input";
import { Drots } from "../../../assets/icons/Drots";
import { AcronymEnum } from "../../../types/types";
import { InvisibleSmartCaptcha } from "@yandex/smart-captcha";
import { getMask } from "../../PhoneMask/PhoneMask";
import LogWithPlatform from "../LogWithPlatform/LogWithPlatform";
import { UserBlock } from "../../UserBlock/UserBlock";
import { CloseIconSmall } from '../../../assets/icons/CloseIconSmall';
import cn from 'classnames';
import { LogWithEsia } from "../LogWithEsia/LogWithEsia";

const Once: FC = () => {
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [visible, setVisible] = useState(false);
  const [isCookiesModalVisible, setIsCookiesModalVisible] = useState<boolean>(false);
  const [token, setToken] = useState('');
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { _load_button, _errors, _auth, block } = useAppSelector(
    ({ userSlice }) => userSlice
  );
  const [, forceUpdate] = useState({});
  const [countryCode, setCountryCode] = useState("7");
  const [mask, setMask] = useState("(000)000-00-00");
  const [acronym, setAcronym] = useState<AcronymEnum>(AcronymEnum.rf)

  useEffect(() => {
    if (_auth) {
      dispatch(Auth_Confirm(false));
    }
    forceUpdate({});
  }, []);

  useEffect(() => {
    setPhone("");
    setEmail('');
  }, [acronym]);

  useEffect(() => {
    if (token !== '') {
      handlerClick()
    }
  }, [token])

  const handlerClick = () => {
    let value = '';
    if (countryCode !== 'mail') {
      value = `${countryCode === '7' ? '8' : countryCode}` + phone.replace(/[^+\d]/g, "");
    }

    dispatch(onceFunc({ phone: value, email: email, acronym: acronym, verify_token: token, setVisible }));
  };

  const hideBlockModal = () => {
    dispatch(SetUserBlock(false));
  }

  const handleChallengeHidden = useCallback(() => setVisible(false), []);

  const handleButtonClick = () => {
    setVisible(true);
  }

  const handleChange = (currentAcronym = AcronymEnum.rf) => {
    const code = phoneRegions.find((x: any) => x.acronym === currentAcronym)?.code || '';
    const currentCountryCode = code.slice(1);
    setCountryCode(currentCountryCode);
    setAcronym(currentAcronym)
    setPhone("0");
    setMask(getMask(currentCountryCode))
  };

  const onHandlerChangePhone = (e: any) => {
    if (e.type === "paste") {
      e.preventDefault();
    }

    setPhone(e.target.value)
  };

  const cookiesAccept = localStorage.getItem('cookiesAccept');

  useEffect(() => {
    if (cookiesAccept !== 'true') {
      setIsCookiesModalVisible(true)
    }
  },[])

  const onClickHandler = () => {
    localStorage.setItem('cookiesAccept', 'true');
    setIsCookiesModalVisible(false)
  }

  const phoneIsValid = (!!phone && phoneValid.test(phone)) || emailValid.test(email);

  return (
    <Row justify={"center"} align={"middle"}>
      <Col span={24}>
        <Form form={form} layout="vertical">
          <Col className={styles.title}>
            <Text className={styles.titleText}>Введите номер телефона</Text>
          </Col>
          <Col className={styles.notes}>
            <Text className={styles.notesText}>
              Мы отправим код подтверждения на ваш email, указанный при регистрации
            </Text>
          </Col>
          <Form.Item
            className={styles.formBlock}
            rules={phoneRules}
            hasFeedback
          >
            <Input.Group className={styles.inpGroup}>
              <Select
                defaultValue={phoneRegions[0].acronym}
                className={styles.groupSelect}
                onChange={handleChange}
              >
                {phoneRegions.map((item: any) => (
                  <Select.Option
                    key={item.acronym}
                    value={item.acronym}
                  >
                    <img
                      src={item.icon}
                      alt=""
                      width={20}
                    />
                    {item.code}
                  </Select.Option>
                ))}
              </Select>
              <span className={styles.line} />
              {acronym === 'EM'
                ? <Input
                  value={email}
                  size="middle"
                  type="text"
                  allowClear
                  placeholder="Введите почту"
                  onChange={(e) => setEmail(e.target.value)}
                  onPressEnter={handleButtonClick}
                />
                :<MaskedInput
                  mask={mask}
                  size="middle"
                  allowClear
                  name="phone"
                  type="text"
                  placeholder={mask}
                  value={phone}
                  onChange={onHandlerChangePhone}
                  onFocus={onHandlerChangePhone}
                  onPressEnter={handleButtonClick}
                />
              }

              <Icon component={Drots} className={styles.drots} />
            </Input.Group>
          </Form.Item>

          <Col className={styles.notes}>
            <Text className={styles.notes__text}>
              Если у вас еще нет аккаунта, просто войдите в систему и ваш
              аккаунт будет создан автоматически.
            </Text>
          </Col>

          <Form.Item shouldUpdate>
            {() => (
              <Button
                type="primary"
                className={styles.buttonSubmit}
                block
                disabled={!phoneIsValid}
                loading={_load_button}
                onClick={handleButtonClick}
              >
                Продолжить
              </Button>
            )}
          </Form.Item>

          <div className={styles.lineOr}>
            <span />
            Или
            <span />
          </div>

          <LogWithEsia />

          <LogWithPlatform />

          <InvisibleSmartCaptcha
            key={visible ? 'captchaVisible' : 'captchaHidden'}
            sitekey={`${process.env.REACT_APP_CLIENT_KEY}`}
            onSuccess={setToken}
            onChallengeHidden={handleChallengeHidden}
            visible={visible}
          />

          <div className={styles.errors}>
            {_errors[0] && (
              <Text type="danger">
                <WarningOutlined color={"red"} />
                {_errors}
              </Text>
            )}
          </div>
        </Form>
        <Modal
          open={block}
          footer={null}
          onCancel={hideBlockModal}
        >
          <UserBlock />
        </Modal>
      </Col>

      <div className={cn(styles.cookiesBlock, {[styles.visible]: isCookiesModalVisible})}>
        <div className={styles.block}>
          <p className={styles.text}>
            Мы используем cookies для обеспечения лучшего опыта на нашем сайте. Продолжая использовать сайт,
            вы соглашаетесь на использование cookies, как описано в нашей
            <a href={process.env.REACT_APP_PRIVACY_POLICY} target="_blank" rel="noreferrer"> Политике конфиденциальности</a>.
          </p>
          <Icon
            component={CloseIconSmall}
            onClick={() => setIsCookiesModalVisible(false)}
          />
        </div>
        <Button
          className={styles.btnOk}
          type="primary"
          key="submit"
          onClick={onClickHandler}
        >
          Принять
        </Button>
      </div>
    </Row>
  );
};

export default Once;
