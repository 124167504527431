import { FC } from "react";
import styles from './UserBlock.module.css';
import Icon from "@ant-design/icons";
import { Block } from "../../assets/icons/Block";
import { useAppSelector } from "../../store";

export const UserBlock: FC = () => {
  const { blockData } = useAppSelector(({ userSlice }) => userSlice);

  return (
    <div className={styles._container}>
      <div className={styles._body}>
        <Icon component={Block}></Icon>
        <div className={styles._text}>
          <h2 className='auth__title_block'>{blockData?.header}</h2>
          <p>Пользователь заблокирован на 30 минут</p>
        </div>
      </div>
    </div>
  )
}
