import axios, {AxiosResponse} from "axios";
import {IPostResponse} from "../../types/types";

const base_url: any = process.env.REACT_APP_AUTH_BASE_URL;
const endpoints = {
  confirm_code: process.env.REACT_APP_AUTH_CONFIRM_CODE,
  sing_in: process.env.REACT_APP_AUTH_SIGN_IN,
  get_code: process.env.REACT_APP_AUTH_GET_CODE,
  get_user: process.env.REACT_APP_AUTH_GET_USER,
  sign_out: process.env.REACT_APP_AUTH_SIGN_OUT,
  recovery_request: process.env.REACT_APP_AUTH_RECOVER_REQUEST,
  recovery_check: process.env.REACT_APP_AUTH_RECOVER_CHECK,
  recovery_save: process.env.REACT_APP_AUTH_RECOVER_SAVE,
  login_with_leader_id: process.env.REACT_APP_AUTH_LOGIN_WITH_LEADER_ID,
  get_esia_login_link: process.env.REACT_APP_AUTH_GET_ESIA_LOGIN_LINK,
  esia_login: process.env.REACT_APP_AUTH_ESIA_LOGIN,
  get_salted_token: process.env.REACT_APP_AUTH_GET_SALTED_TOKEN
};
export default class AuthService {
  static async $confirmCode(data: string) {
    return await axios.post(base_url + endpoints.confirm_code, data);
  }

  static async $auth(data: string) {
    return await axios.post(base_url + endpoints.sing_in, data);
  }

  static async $getting_key(contactInfo: { email: string | undefined; } | { phone: string; }, send_to: string = '', verify_token?: string) {
    const data = {
      ...contactInfo,
      send_to,
      verify_token
    };
    return await axios.post(
      base_url + endpoints.get_code,
      JSON.stringify(data)
    );
  }

  static async $get_user(token: string) {
    return await axios.post(base_url + endpoints.get_user, token);
  }

  static async $logout(token: string) {
    return await axios.post(base_url + endpoints.sign_out, token);
  }

  static async $recover_access(data: string) {
    return await axios.post(base_url + endpoints.recovery_request, data);
  }

  static async $recovery_check(data: string) {
    return await axios.post(base_url + endpoints.recovery_check, data);
  }

  static async $recovery_save(data: string) {
    return await axios.post(base_url + endpoints.recovery_save, data);
  }

  static async $auth_with_leader_id(redirect_url: string) {
    return await axios.post(base_url + endpoints.login_with_leader_id, redirect_url);
  }

  static async $get_esia_login_link(): Promise<AxiosResponse<IPostResponse<string>>> {
    if (base_url && endpoints.get_esia_login_link) {
      return axios.post(base_url + endpoints.get_esia_login_link);
    }
    return Promise.reject(new Error('Base URL or endpoint for ESIA login link is not defined'));
  }

  static async $esia_login(data: string): Promise<AxiosResponse<IPostResponse<string>>> {
    if (base_url && endpoints.esia_login) {
      return axios.post(base_url + endpoints.esia_login, data);
    }
    return Promise.reject(new Error('Base URL or endpoint for ESIA login is not defined'));
  }
  static async $get_salted_token(token: string): Promise<AxiosResponse<IPostResponse<string>>> {
    if (base_url && endpoints.get_salted_token) {
      return axios.post(base_url + endpoints.get_salted_token, token);
    }
    return Promise.reject(new Error('Base URL or endpoint for salted token is not defined'));
  }
}
