import React, {FC} from 'react';
import style from './LineLoader.module.css'

const Index: FC = () => {
  return (
    <div className={style.loader}>
      <div className={style.loader__element}/>
    </div>
  )

};

export default Index;