export enum AcronymEnum {
  rf = 'RF',
  kz = 'KZ',
  by = 'BY',
  kg = 'KG',
  uz = 'UZ',
  am = 'AM',
  mn = 'MN',
  cn = 'CN',
  em = 'EM',
}

export interface IInitState {
  _token: string;
  _IsLoading: boolean;
  _load_button: boolean;
  _url: string;
  _params_send: string;
  _forgot: string;
  _phone: string;
  _userEmail: string;
  _userPhone: string;
  email: string;
  telegram: string;
  _auth: boolean;
  _errors: string[];
  user: {
    email: string;
    password: string;
    roles: string[];
  };
  _isModal: boolean;
  _token_url: string;
  acronym: AcronymEnum;
  verify_token: string;
  errors_pages: boolean;
  badGateway: boolean;
  block: boolean;
  blockData?: UserBlockData;
  new_user: boolean
}

export interface ICounter {
  counter: number;
  setCounter(c: number): void;
  telegram: string;
  email: string;
}

export interface IUser {
  userSlice: {
    _params_send: string,
    _url: {
      redirect: string
    }
  }

}

export interface ILogin {
  phone: string,
  email: string,
  password?: string,
  acronym: string,
}

export interface IConfirm {
  phone: string,
  code: string,
  email: string,
  _userEmail: string,
  telegram: string,
}

export interface IRecoveryAccess {
  phone: string,
  setIsModalSendSuccess: (value: (((prevState: boolean) => boolean) | boolean)) => void,
  setIsForgotPasswordModalVisible: (value: (((prevState: boolean) => boolean) | boolean)) => void,
  setIsModalSendError: (value: (((prevState: boolean) => boolean) | boolean)) => void
}

export interface ISaveNewPassword {
  _token_url: string,
  password: string,
}

export interface IOnceFuncArguments {
  phone?: string;
  email?: string;
  p?: boolean;
  acronym: AcronymEnum;
  verify_token: string;
  setVisible: (visible: boolean) => void;
}

export interface UserBlockData {
  header: string;
  title: string;
  footer: string;
}

export interface IRecoverModal {
    isForgotPasswordModalVisible: boolean;
    setIsForgotPasswordModalVisible: (b: boolean) => void;
    handleChange: (value: string) => void;
    maskRecovery: string;
    phoneRecovery: string;
    setPhoneRecovery: (phone: string) => void;
    onRecoveryAccess: () => void;
    formModal: any;
}

export interface IRepeatFuncAction {
  phone: string;
  email: string;
  send_to: string;
  verify_token: string;
}

export interface IError {
  key: number;
  message: string;
}

export interface IPostResponse<IValues = []> {
  errors: IError[] | [],
  need_fields: boolean;
  status: boolean;
  tm_reg: string;
  values: IValues[];
}

export interface IEsiaLoginRequest {
  code: string;
  state: string;
}
