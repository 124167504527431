export const TechMainIcon = () => (
  <svg width="444" height="280" viewBox="0 0 444 280" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="101" y="103" width="156" height="156" rx="32" transform="rotate(-16 101 103)" fill="#E0F1FF"/>
    <circle cx="366" cy="156" r="7" stroke="#3B4168" strokeWidth="2"/>
    <path d="M60.4541 95.1411L68.7363 126.051" stroke="#3B4168" strokeWidth="2"/>
    <path d="M80.0498 106.455L49.1402 114.737" stroke="#3B4168" strokeWidth="2"/>
    <path
      d="M197 224.333H247C288.667 224.333 305.334 207.667 305.334 166V116C305.334 74.3333 288.667 57.6666 247 57.6666H197C155.334 57.6666 138.667 74.3333 138.667 116V166C138.667 207.667 155.334 224.333 197 224.333Z"
      stroke="#3B4168" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M251.753 195.167V162.667" stroke="#3B4168" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"
          strokeLinejoin="round"/>
    <path d="M251.753 103.083V86.8334" stroke="#3B4168" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"
          strokeLinejoin="round"/>
    <path
      d="M251.756 146.418C263.722 146.418 273.422 136.718 273.422 124.752C273.422 112.785 263.722 103.085 251.756 103.085C239.789 103.085 230.089 112.785 230.089 124.752C230.089 136.718 239.789 146.418 251.756 146.418Z"
      stroke="#3B4168" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M192.247 195.165V178.915" stroke="#3B4168" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"
          strokeLinejoin="round"/>
    <path d="M192.247 119.333V86.8334" stroke="#3B4168" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"
          strokeLinejoin="round"/>
    <path
      d="M192.251 178.916C204.217 178.916 213.917 169.216 213.917 157.25C213.917 145.284 204.217 135.583 192.251 135.583C180.284 135.583 170.584 145.284 170.584 157.25C170.584 169.216 180.284 178.916 192.251 178.916Z"
      stroke="#3B4168" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>)
