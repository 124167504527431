import {AcronymEnum, IInitState, UserBlockData} from "../../types/types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";


const initialState: IInitState = {
   _token: '',
   _IsLoading: true,
   _load_button: false,
   _url: '',
   _params_send: '',
   _forgot: '',
   _phone: '',
   _userEmail: '',
   _userPhone: '',
   email: '',
   telegram: '',
   _auth: false,
   _errors: [''],
   user: {
      email: '',
      password: '',
      roles: ["User"]
   },
   _isModal: false,
   _token_url: '',
   acronym: AcronymEnum.rf,
   verify_token: '',
   errors_pages: false,
   badGateway: false,
   block: false,
   blockData: undefined,
   new_user: false,
}

export const userSlice = createSlice({
   name: "userSlice",
   initialState,
   reducers: {
      Token: (state, action: PayloadAction<string>) => {
         state._token = action.payload
      },
      IsLoading: (state, action: PayloadAction<boolean>) => {
         state._IsLoading = action.payload
      },
      LoadButton: (state, action: PayloadAction<boolean>) => {
         state._load_button = action.payload
      },
      Phone_Num: (state, action: PayloadAction<string>) => {
         state._phone = action.payload
      },
      User_Email: (state, action: PayloadAction<string>) => {
         state._userEmail = action.payload
      },
      User_Phone: (state, action: PayloadAction<string>) => {
         state._userPhone = action.payload
      },
      Errors: (state, action: PayloadAction<string[]>) => {
         state._errors = action.payload
      },
      ForgotPassword: (state, action: PayloadAction<string>) => {
         state._forgot = action.payload
      },
      Params_url: (state, action: PayloadAction<string>) => {
         state._params_send = action.payload
      },
      Url_Put: (state, action: PayloadAction<string>) => {
         state._url = action.payload
      },
      Auth_Confirm: (state, action: PayloadAction<boolean>) => {
         state._auth = action.payload
      },
      IsModal: (state, action: PayloadAction<boolean>) => {
         state._isModal = action.payload
      },
      EmailPut: (state, action: PayloadAction<string>) => {
         state.email = action.payload
      },
      SetTelegram: (state, action: PayloadAction<string>) => {
         state.telegram = action.payload
      },
      TokenUrl: (state, action: PayloadAction<string>) => {
         state._token_url = action.payload
      },
      SetAcronym: (state, action: PayloadAction<AcronymEnum>) => {
         state.acronym = action.payload
      },
      SetVerifyToken: (state, action: PayloadAction<string>) => {
         state.verify_token = action.payload
      },
      IsErrorPage: (state, action: PayloadAction<boolean>) => {
         state.errors_pages = action.payload
      },
      SetStatusCode: (state, action: PayloadAction<boolean>) => {
         state.badGateway = action.payload
      },
      SetUserBlock: (state, action: PayloadAction<boolean>) => {
         state.block = action.payload
      },
      SetUserBlockData: (state, action: PayloadAction<UserBlockData>) => {
         state.blockData = action.payload
      },
      NewUser: (state, action: PayloadAction<boolean>) => {
         state.new_user = action.payload
      },
   },
   extraReducers: {},
})

export const {
   Token,
   IsLoading,
   LoadButton,
   Phone_Num,
   User_Email,
   User_Phone,
   Errors,
   ForgotPassword,
   Params_url,
   Url_Put,
   Auth_Confirm,
   IsModal,
   EmailPut,
   SetTelegram,
   TokenUrl,
   SetAcronym,
   SetVerifyToken,
   IsErrorPage,
   SetStatusCode,
   SetUserBlock,
   SetUserBlockData,
   NewUser,
} = userSlice.actions

export default userSlice.reducer;

