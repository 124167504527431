import {Col, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import {Outlet} from "react-router-dom";
import {useAppSelector} from "../store";
import cn from 'classnames';
import {FC, useEffect} from "react";

const Main: FC = () => {
  const {_auth, errors_pages} = useAppSelector(({userSlice}) => userSlice)

  const isBgHidden = (): string => {
    if (_auth || errors_pages) {
      return '_backgroundHide'
    }
    return ''
  }

  useEffect(() => {
    const footer = document.querySelector('#footer') as HTMLElement
    const body = document.querySelector('body')
    if (_auth || errors_pages) {
      footer.dataset.theme = 'dark'
      body?.classList.add('darkBody')
      body?.classList.remove('lightBody')
    } else {
      footer.dataset.theme = 'light'
      body?.classList.add('lightBody')
      body?.classList.remove('darkBody')
    }
  },[_auth, errors_pages])

  return (
    <Layout className={cn('_backgroundColor', '_body', isBgHidden())}>
      <Content>
        <Row className='app' justify={"center"} align='middle'>
          <Col>
            <Outlet/>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};


export default Main;
