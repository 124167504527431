import React, {useEffect} from 'react';
import {useAppDispatch} from "../../store";
import {IsErrorPage, SetStatusCode} from "../../store/slices/userSlice";
import {Button} from "antd";
import Icon from "@ant-design/icons";
import {ReloadIcon} from "../../assets/icons/ReloadIcon";
import styles from './badGateWay.module.css'
import {BadGatewayIcon} from "../../assets/icons/BadGatewayIcon";
import {useNavigate} from "react-router-dom";

export const BadGateway = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const reloadPage = () => {
    dispatch(SetStatusCode(false))
    navigate('/login')
  }

  useEffect(() => {
    dispatch(IsErrorPage(true))
    return () => {
      dispatch(IsErrorPage(false))
    }
  }, [])
  return (
    <div className={styles.badGateway}>
      <div className={styles.content}>
        <Icon className={styles.errorIcon} component={BadGatewayIcon}/>
        <div className={styles.errorMessage}>
          <h1 className={styles.title}>Что-то пошло не так</h1>
          <p className={styles.description}>но мы скоро всё починим</p>
          <Button
            className={styles.reloadBtn}
            type='primary'
            onClick={reloadPage}
          >
            <Icon component={ReloadIcon}/>
            Перезагрузить страницу
          </Button>
        </div>
      </div>
    </div>
  );
};
