//regEx для валидации номера и имейда
import {AcronymEnum} from "../types/types";
import {arm, blr, chn, kaz, kgz, mng, rus, uzb} from "../assets/flags";

export const phoneValid = new RegExp(/^(\+)?(\(\d{2,3}\) ?|\d)(([ \-]?\d)|( ?\(\d{2,3}\) ?)){5,12}\d$/)
export const passValid = new RegExp(/^[a-zA-Z0-9^\u0021-\u007E]+$/)
export const emailValid = new RegExp(/^([a-zA-Z0-9_.+-]+)@([a-zA-Z0-9-]+\.[a-zA-Z0-9-а-яА-Я.]+)$/);
export const emailPattern = new RegExp(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/);


const errosServ = [
    {key: 99, message: "Неизвестная ошибка"},
    {key: 100, message: "Пустой запрос"},
    {key: 101, message: "Запрос на чтение ошибки"},
    {key: 102, message: "Ошибка запроса демаршалировать"},
    {key: 103, message: "Неверные данные"},
    {key: 104, message: "Неправильный метод"},
    {key: 105, message: "токен не найден"},
    {key: 106, message: "Неверный код"},
    {key: 107, message: "Путь не разрешен"},
    {key: 108, message: "Ошибка отправки данных регистрации пользователя"},
    {key: 109, message: "Введен неправильный номер телефона"},
    {key: 110, message: "Неправильный ввели адрес электронной почты пользователя"},
    {key: 111, message: "Неверный пароль пользователя"},
    {key: 112, message: "телефон уже существует"},
    {key: 114, message: "Эл. адрес уже существует"},
    {key: 114, message: "Ошибка добавить пользователя"},
    {key: 115, message: "Ошибка отправки данных авторизации пользователя"},
    {key: 116, message: "Электронная почта и телефон не переданы"},
    {key: 117, message: "Пароль не прошел"},
    {key: 118, message: "Номер телефона или пароль не верны"},
    {key: 119, message: "Ошибка отправки смс"},
    {key: 120, message: "код не прошел"},
    {key: 121, message: "Пользователь не найден"},
    {key: 122, message: "Ошибка проверки кода пользователя"},
    {key: 123, message: "Токен не найден"},
    {key: 124, message: "Ошибка обновления пользователя"},
    {key: 161, message: "Неверный код"},
    {key: 165, message: "Ошибка отправки электронного письма"},
    {key: 171, message: "Вы не можете запросить код более одного раза в две минуты"},
    {key: 172, message: "Пользователь удален или заблокирован"},
    {key: 180, message: "Ошибка аутентификации пользователя"},
    {key: 201, message: "Одновременные запросы не допускаются"},
    {key: 236, message: 'Неправильный домен электронной почты'},
    {key: 246, message: 'Отправка sms на иностранный номер невозможна'},
    {key: 265, message: 'Код устарел'},
    {key: 266, message: 'Что-то пошло не так. Повторите попытку позже или обратитесь в службу поддержки.'},
    {key: 267, message: 'Что-то пошло не так. Повторите попытку позже или обратитесь в службу поддержки.'},
    {key: 268, message: 'Что-то пошло не так. Повторите попытку позже или обратитесь в службу поддержки.'},
    {key: 269, message: 'Что-то пошло не так. Повторите попытку позже или обратитесь в службу поддержки.'},
    {key: 270, message: 'Что-то пошло не так. Повторите попытку позже или обратитесь в службу поддержки.'},
    {key: 271, message: 'Невозможно осуществить действие: учетная запись на Госуслугах не подтверждена или находится на проверке.'},
    {key: 272, message: 'Что-то пошло не так. Повторите попытку позже или обратитесь в службу поддержки.'},
    {key: 273, message: 'Отсутствует привязка к сервису Госуслуг. Осуществите привязку в своем профиле ЕМОП.'},
]

export const agreementUrl = process.env.REACT_APP_AGREEMENT_PDF

export const errorsKey = (key: number) => errosServ.filter(er => er.key === key).map(item => item.message)

export const regexObj: {
    [key in AcronymEnum]: {
        regex: RegExp,
        regexTransform: string
    }
} = {
    RF: {
        regex: /(\+7|8)[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g,
        regexTransform: '+7 $2 $3 $4 $5'
    },
    KZ: {
        regex: /(\+7|8)[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g,
        regexTransform: '+7 $2 $3 $4 $5'
    },
    BY: {
        regex: /(375)[\s(]?(\d{2})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g,
        regexTransform: '+375 $2 $3 $4 $5'
    },
    KG: {
        regex: /(996)[\s(]?(\d{3})[\s)]?(\d{2})[\s-]?(\d{2})[\s-]?(\d{2})/g,
        regexTransform: '+996 $2 $3 $4 $5'
    },
    UZ: {
        regex: /(998)[\s(]?(\d{2})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g,
        regexTransform: '+998 $2 $3 $4 $5'
    },
    AM: {
        regex: /(374)[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g,
        regexTransform: '+374 $2 $3 $4 $5'
    },
    MN: {
        regex: /(976)[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{4})/g,
        regexTransform: '+976 $2 $3 $4'
    },
    CN: {
        regex: /(86)[\s(]?(\d{3})[\s)]?(\d{4})[\s-]?(\d{4})/g,
        regexTransform: '+86 $2 $3 $4'
    },
    EM: {
        regex: /(86)[\s(]?(\d{3})[\s)]?(\d{4})[\s-]?(\d{4})/g,
        regexTransform: '+86 $2 $3 $4'
    },
}

export const recovery_form = 'формой восстановления';

export const send_support_button = 'Отправить в поддержку';

export const send_form_template = 'Пользователь заблокирован из-за превышения лимита смс';

export const mailto_support = 'mailto:unionepro.support@innopolis.ru&body='

export const phoneRegions = [
    { code: "+7", acronym: AcronymEnum.rf, icon: rus },
    { code: "+7", acronym: AcronymEnum.kz, icon: kaz },
    { code: "+375", acronym: AcronymEnum.by, icon: blr },
    { code: "+996", acronym: AcronymEnum.kg, icon: kgz },
    { code: "+998", acronym: AcronymEnum.uz, icon: uzb },
    { code: "+374", acronym: AcronymEnum.am, icon: arm },
    { code: "+976", acronym: AcronymEnum.mn, icon: mng },
    { code: "+86", acronym: AcronymEnum.cn, icon: chn },
    { code: "email", acronym: AcronymEnum.em, icon: 'em' },
];

export const phoneRules = [
    { required: true, message: "введите номер телефона", min: 4 },
    {
        validator: (_: any, value: any) => {
         return (
           value &&
           emailValid.test(value) ||
           phoneValid.test(value)
             ? Promise.resolve("okay")
             : Promise.reject("")
         )
        }
    },
];

export const PinInputStyle = {
    background: "#FBFDFF",
    border: '1px solid #E4E7F2',
    borderRadius: '16px',
    fontSize: 20,
    color: '#3B4168',
    height: 48,
    width: 48,
    margin: '0 8px',
    fontWeight: 600,
}

export const PinContainerStyle = {
    marginTop: '40px',
    marginBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
}

export const pastelBlue = '#99C6EB';
export const brightRed = '#EB5757';
export const greenRgbColor = 'rgb(25,135,84)';

export const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop()?.split(';').shift();
    }
    return undefined;
}

export const getDomain = window.location.hostname.split('.').slice(-2).join('.')
