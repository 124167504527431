import React, {FC, useEffect, useMemo, useState} from 'react';
import {Button, Col, Form, Input, Row, Modal, Select} from "antd";
import Text from "antd/es/typography/Text";
import styles from './Anytime.module.css'
import { emailValid, phoneValid } from "../../../utils";
import {_login, _recovery_access} from "../../../store/actions/userAction";
import Icon, {WarningOutlined} from "@ant-design/icons";
import MaskedInput from "antd-mask-input";
import {DoneIcon} from "../../../assets/icons/DoneIcon";
import {CooliconIcon} from "../../../assets/icons/CooliconIcon";
import {Drots} from "../../../assets/icons/Drots"
import {kgz, rus, blr, kaz, mng, arm, chn, uzb} from "../../../assets/flags"
import {useAppDispatch, useAppSelector} from "../../../store";
import {getMask} from '../../PhoneMask/PhoneMask';
import PhoneRecoveryModal from "../../PhoneRecoveryModal/PhoneRecoveryModal";
import LogWithPlatform from '../LogWithPlatform/LogWithPlatform';
import {LogWithEsia} from "../LogWithEsia/LogWithEsia";

const Anytime: FC = () => {
  const [phone, setPhone] = useState('')
  const [emailValue, setEmailValue] = useState("");
  const [phoneRecovery, setPhoneRecovery] = useState('')
  const [password, setPassword] = useState('')
  const [form] = Form.useForm();
  const [formModal] = Form.useForm();
  const dispatch = useAppDispatch()
  const {_load_button, _errors, email} = useAppSelector(({userSlice}) => userSlice)
  const [, forceUpdate] = useState({});
  const [isForgotPasswordModalVisible, setIsForgotPasswordModalVisible] = useState(false)
  const [isModalSendSuccess, setIsModalSendSuccess] = useState(false)
  const [isModalSendError, setIsModalSendError] = useState(false)
  const [countryCode, setCountryCode] = useState("+7");
  const [countryCodeRecovery, setCountryCodeRecovery] = useState("+7");
  const [mask, setMask] = useState("(000)000-00-00");
  const [maskRecovery, setMaskRecovery] = useState("(000)000-00-00");
  const [acronym, setAcronym] = useState("RU");


  useEffect(() => {
    forceUpdate({});
  }, []);

  const handlerClick = () => {
    let value = '';
    if (countryCode !== 'mail') {
      value = countryCode + phone.replace(/[^+\d]/g, "");
    }

    dispatch(_login({phone: value, email: emailValue, password, acronym}));
  }

  const onRecoveryAccess = () => {
    const phone = countryCodeRecovery + phoneRecovery.replace(/[^+\d]/g, '');
    dispatch(_recovery_access({phone, setIsModalSendSuccess, setIsForgotPasswordModalVisible, setIsModalSendError}))
  }

  const showForgotPasswordModel = () => {
    setIsForgotPasswordModalVisible(true)
  }

  const LoginPressEnter = () => {
    if (phone && password) {
      handlerClick()
    }
  }

  const hiddenEmail = useMemo(() => {
    const splitted = email.split('');
    let star;
    for (let i = 0; i < splitted.length; i++) {
      if (splitted[i] === '@' && i > 3) {
        star = '*'.repeat(i - 3);
        return star + splitted.splice(i - 3, splitted.length - 1).join('');

      } else if (splitted[i] === '@') {
        star = '*'.repeat(i);
        return star + splitted.splice(i, splitted.length - 1).join('');
      }
    }
  }, [email]);

  const handleChange = (value: string) => {
    const countryValue = JSON.parse(value);
    let currentCountryCode = countryValue.code;
    if (countryValue.code !== 'email') {
      currentCountryCode = countryValue.code.replace(/[^0-9]/g, "");
    }
    setAcronym(countryValue.acronym);
    if (!isForgotPasswordModalVisible) {
      setPhone('0');
      setCountryCode(currentCountryCode);
      setMask(getMask(currentCountryCode));
    } else {
      setPhoneRecovery('0');
      setCountryCodeRecovery(currentCountryCode);
      setMaskRecovery(getMask(currentCountryCode));
    }
  };

  const phoneIsValid = !!phone && phoneValid.test(phone) || emailValid.test(emailValue);

  const selectOptions = (
    <>
      <Select.Option value='{"code": "+7", "acronym": "RF"}'><img src={rus} alt="" width={20}/> +7</Select.Option>
      <Select.Option value='{"code": "+7", "acronym": "KZ"}'><img src={kaz} alt="" width={20}/> +7</Select.Option>
      <Select.Option value='{"code": "+375", "acronym": "BY"}'><img src={blr} alt="" width={20}/> +375</Select.Option>
      <Select.Option value='{"code": "+996", "acronym": "KG"}'><img src={kgz} alt="" width={20}/> +996</Select.Option>
      <Select.Option value='{"code": "+998", "acronym": "UZ"}'><img src={uzb} alt="" width={20}/> +998</Select.Option>
      <Select.Option value='{"code": "+374", "acronym": "AM"}'><img src={arm} alt="" width={20}/> +374</Select.Option>
      <Select.Option value='{"code": "+976", "acronym": "MN"}'><img src={mng} alt="" width={20}/> +976</Select.Option>
      <Select.Option value='{"code": "+86", "acronym": "CN"}'><img src={chn} alt="" width={20}/> +86</Select.Option>
      <Select.Option value='{"code": "email", "acronym": "EM"}'>email</Select.Option>
    </>
  );

  useEffect(() => {
    if (countryCode === "7" || countryCode === "+7") {
      setCountryCode("8")
    }
    if (countryCodeRecovery === "7" || countryCodeRecovery === "+7") {
      setCountryCodeRecovery("8")
    }
    if (isForgotPasswordModalVisible) {
      setPhoneRecovery("");
    } else {
      setPhone("");
    }
  }, [countryCode, countryCodeRecovery])


  const onHandlerChangePhone = (e: any) => {
    if (e.type === "paste") {
      e.preventDefault();
    }

    setPhone(e.target.value)
  };

  return (
    <Row justify={"center"} align={"middle"}>
      <Col span={24}>
        <Form form={form} layout="vertical">
          <Col className={styles.title}>
            <Text className={styles.titleText}>Введите номер телефона и пароль</Text>
          </Col>

          <Form.Item
            name="phone"
            className={styles.formBlock}
            rules={[
              {required: true, message: "введите номер телефона", min: 4},
              {
                validator: (_, value) =>
                  value &&
                  // emailValid.test(value) ||
                  phoneValid.test(value)
                    ? Promise.resolve("okay")
                    : Promise.reject(""),
              },
            ]}
            hasFeedback
          >
            <Input.Group className={styles.inpGroup}>
              <Select
                defaultValue='{"code": "+7", "acronym": "RF"}'
                onChange={handleChange}
              >
                {selectOptions}
              </Select>
              <span className={styles.line}/>
              {acronym === 'EM'
                ? <Input
                  value={emailValue}
                  size="middle"
                  type="text"
                  allowClear
                  placeholder="Введите почту"
                  onChange={(e) => setEmailValue(e.target.value)}
                  onPressEnter={LoginPressEnter}
                />
                : <MaskedInput
                  mask={mask}
                  size="middle"
                  allowClear
                  name="phone"
                  type="text"
                  placeholder={mask}
                  value={phone}
                  onChange={onHandlerChangePhone}
                  onPressEnter={LoginPressEnter}
                />
              }
              <Icon component={Drots} className={styles.drots}/>
            </Input.Group>
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {required: true, message: "Пожалуйста, введите ваш пароль!"},
            ]}
            hasFeedback
          >
            <Input.Password
              allowClear
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="*********"
              onPressEnter={LoginPressEnter}
            />
          </Form.Item>

          <Col className={styles.forgotPassword}>
            <Button
              className={styles.forgotPasswordLink}
              size="large"
              type="link"
              onClick={showForgotPasswordModel}
            >
              Забыли пароль?
            </Button>
          </Col>

          <Form.Item shouldUpdate>
            {() => (
              <Button
                type="primary"
                block
                className={styles.buttonLogin}
                disabled={!phoneIsValid}
                loading={_load_button}
                onClick={handlerClick}
              >
                Войти
              </Button>
            )}
          </Form.Item>

          <div className={styles.lineOr}>
            <span />
            Или
            <span />
          </div>

          <LogWithEsia />

          <LogWithPlatform />
        </Form>
      </Col>
      <div className={styles.errors}>
        {_errors[0] && (
          <Text type="danger">
            <WarningOutlined color={"red"}/>
            {_errors}
          </Text>
        )}
      </div>

      <PhoneRecoveryModal
         isForgotPasswordModalVisible={isForgotPasswordModalVisible}
         setIsForgotPasswordModalVisible={setIsForgotPasswordModalVisible}
         handleChange={handleChange}
         maskRecovery={maskRecovery}
         phoneRecovery={phoneRecovery}
         setPhoneRecovery={setPhoneRecovery}
         onRecoveryAccess={onRecoveryAccess}
         formModal={formModal}
      />

      <Modal
        className={styles.modalSendMessageSuccess}
        open={isModalSendSuccess}
        closable={false}
        footer={false}
        onCancel={() => setIsModalSendSuccess(false)}
      >
        <Icon component={CooliconIcon}/>
        <h2>Письмо отправлено на {hiddenEmail}</h2>
      </Modal>

      <Modal
        className={styles.modalForgotPassword}
        open={isModalSendError}
        closable={false}
        width={520}
        onCancel={() => setIsModalSendError(false)}
        footer={[
          <Button
            className={`${styles.buttonLogin} ${styles.btnWidth}`}
            type="primary"
            key="submit"
            loading={_load_button}
            onClick={() => setIsModalSendError(false)}
          >
            Войти в систему
          </Button>,
        ]}
      >
        <Icon
          component={DoneIcon}
          className={styles.emptyIcon}
        />
        <h2 className={styles.emptyModalTitle}>Отсутствует e-mail</h2>
        <p className={styles.emptyModalText}>
          К сожалению, вы не указывали электронную почту, воспользуйтесь входом
          через одноразовый код
        </p>
      </Modal>
    </Row>
  );
};

export default Anytime;
