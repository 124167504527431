import React, {FC, useEffect} from 'react';
import {Button, Col, Form, Input, Row} from "antd";
import styles from "./Recovery.module.css"
import {passValid} from "../../utils";
import {useNavigate, useSearchParams} from "react-router-dom";
import {check_recovery_token, save_new_password} from "../../store/actions/userAction";
import LineLoader from "../../components/LineLoader/LineLoader";
import {useAppDispatch, useAppSelector} from "../../store";

const Recovery: FC= () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch()
  const {_IsLoading, _token_url} = useAppSelector(({userSlice}) => userSlice);
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(check_recovery_token(searchParams.get("token") || ''))
  }, [])

  const cancelRecovery = () => {
    navigate('/login')
  }

  const onSaveNewPassword = (values: any) => {
    const {password} = values
    dispatch(save_new_password({_token_url, password}))
  }

  const checkPassword = (value: string) => {
    if (value && value.length < 5) {
      return Promise.reject('Пароль должен содержать не менее 5 символов');
    } else if (value && !value.match(passValid)) {
      return Promise.reject('Пароль должен содержать латинские буквы, цифры и символы');
    } else if (value && value.length > 20) {
      return Promise.reject('Пароль не должен содержать более 20 символов')
    } else {
      return Promise.resolve(true)
    }
  }

  return (
    <>
      {_IsLoading
        ? <LineLoader/>
        :  <div className={styles.recovery}>
            <Row>
              <Col>
                <h2 className={styles.title}>
                  Новый пароль
                </h2>
                <p className={styles.text}>
                  Вы можете создать новый постоянный пароль для входа в систему. Пароль должен содержать латинские буквы и цифры.
                </p>
                <Form form={form} layout="vertical" onFinish={onSaveNewPassword}>
                  <Form.Item
                    hasFeedback
                    label='Придумайте пароль'
                    name='password'
                    rules={[{ required: true, message: 'пожалуйста введи пароль!' },
                      {validator: (_, value) => checkPassword(value)}
                    ]}>
                    <Input.Password
                      type='text'
                      placeholder='Введите пароль'
                    />
                  </Form.Item>

                  <Form.Item
                    dependencies={['password']}
                    rules={[{ required: true, message: 'пожалуйста введи пароль!' },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('пароли не совпадают'));
                        },
                      })
                    ]}
                    hasFeedback
                    label='Повторите пароль'
                    name='confirmPassword'
                  >
                    <Input.Password type='text' placeholder='Подтвердите пароль' />
                  </Form.Item>

                  <Form.Item shouldUpdate className={styles.saveBlock}>
                    {() => (
                      <Button
                        type="primary"
                        htmlType="submit"
                        className={styles.button}
                        disabled={!form.isFieldsTouched(true) || !!form.getFieldsError().filter(({errors}) => errors.length).length}
                      >
                        Сохранить
                      </Button>
                    )}
                  </Form.Item>
                </Form>
              </Col>

              <Col className={styles.cancelBlock}>
                <Button
                  className={styles.buttonCancel}
                  size='large'
                  type="link"
                  onClick={cancelRecovery}
                >
                  Отмена
                </Button>
              </Col>
            </Row>
          </div>
      }

    </>
  );
};

export default Recovery;