import React, {FC, useEffect} from 'react';
import {IsErrorPage, SetStatusCode} from "../../store/slices/userSlice";
import {useAppDispatch} from "../../store";
import styles from './serviceUpdate.module.css'
import Icon from "@ant-design/icons";
import {TechMainIcon} from "../../assets/icons/TechMainIcon";

export const ServiceUpdate: FC = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(IsErrorPage(true))
    return () => {
      dispatch(IsErrorPage(false))
      dispatch(SetStatusCode(false))
    }
  }, [])

  return (
    <div className={styles.content}>
      <Icon className={styles.errorIcon} component={TechMainIcon}/>
      <div className={styles.message}>
        <h1>Технические работы</h1>
        <p>
          Наши специалисты работают над улучшением сервиса, пожалуйста, зайдите позднее.
          Спасибо за понимание, вы лучшие!
        </p>
      </div>
    </div>
  );
};
