import React, {useLayoutEffect} from 'react';
import {agreementUrl} from "../../utils";

export const Agreement = () => {
  useLayoutEffect(() => {
    if (agreementUrl) window.location.href = agreementUrl
  }, [])
  return (
    <>
    </>
  );
};