import React from 'react';

export const DoneIcon = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M59 5.00049V55.0005C59.0003 56.0615 58.5789 57.079 57.8287 57.8293C57.0785 58.5795 56.0609 59.0008 55 59.0005H47L48 1.00049H55C56.0609 1.0002 57.0785 1.42153 57.8287 2.17174C58.5789 2.92195 59.0003 3.93953 59 5.00049Z" fill="#FFE0B9"/>
    <path d="M52.7069 1.19971H5.49153C3.12095 1.19971 1.19922 2.97822 1.19922 5.17212V54.8273C1.19922 57.0212 3.12095 58.7997 5.49153 58.7997H52.7069C55.0775 58.7997 56.9992 57.0212 56.9992 54.8273V5.17212C56.9992 2.97822 55.0775 1.19971 52.7069 1.19971Z" fill="#FFFEFC"/>
    <path d="M56.9992 29.7765V54.8375C56.9995 55.8884 56.5474 56.8964 55.7424 57.6395C54.9373 58.3826 53.8454 58.8 52.7069 58.7997H5.49153C4.35304 58.8 3.26109 58.3826 2.45606 57.6395C1.65103 56.8964 1.19891 55.8884 1.19922 54.8375V39.8108C4.15838 37.99 7.24486 36.3521 10.4384 34.9076C12.2412 34.0954 14.1298 33.3359 16.1043 32.6293C18.2075 31.8666 20.4037 31.1864 22.6929 30.5888C24.5708 30.0935 26.5095 29.6709 28.509 29.3209C29.067 29.2218 29.6358 29.1327 30.2045 29.0435C32.3544 28.709 34.5212 28.4742 36.6966 28.3402C38.0809 28.2511 39.4866 28.2016 40.9138 28.2016C46.3228 28.1641 51.719 28.6925 56.9992 29.7765Z" fill="#F7B055" fillOpacity="0.12"/>
    <path d="M2.25713 58.1215C2.51665 58.412 2.8082 58.6151 3.11513 58.7191C3.27444 58.7728 3.43662 58.7999 3.59921 58.7997H5.3992C5.23669 58.7998 5.07459 58.7728 4.91536 58.7191C3.79956 58.3389 2.99895 56.7129 2.99921 54.8273V5.17212C2.99904 4.11848 3.25184 3.10792 3.70196 2.36288C4.15208 1.61785 4.76263 1.19942 5.3992 1.19971L3.59921 1.19971C2.96264 1.19942 2.35209 1.61785 1.90197 2.36288C1.45184 3.10792 1.19904 4.11848 1.19922 5.17212V54.8273C1.19853 56.1484 1.59515 57.3834 2.25713 58.1215Z" fill="#FFE0B9"/>
    <path d="M2 4.99951V17.9995V54.9995H0V17.9995V4.99951H2Z" fill="#F7B055"/>
    <path d="M5 60H55C57.7601 59.9968 59.9967 57.7601 60 55V5C59.9967 2.23992 57.7601 0.00325114 55 0H5C2.23993 0.00325114 0.00325114 2.23992 0 5C0 5.39242 1.14631e-05 7.65651 0.00903178 9.00469C0.02578 9.62344 0.499218 9.99844 0.967968 9.99844C1.57266 9.99844 1.99687 9.54375 1.99687 9.00469C2 7.50616 2 5.38362 2 5C2.00182 3.3439 3.3439 2.00182 5 2H55C56.6561 2.00182 57.9982 3.3439 58 5V55C57.9982 56.6561 56.6561 57.9982 55 58H5C3.3439 57.9982 2.00182 56.6561 2 55C2 54.886 1.99972 53.1355 1.99928 50.4297C1.9978 41.2206 1.99453 20.945 1.99453 16.4503C1.99453 15.8578 1.4302 15.4594 1.05469 15.4594C0.665625 15.4594 0.0205511 15.8133 0.0188892 16.4503C0.00866565 20.369 0 54.49 0 55C0.00325114 57.7601 2.23993 59.9968 5 60Z" fill="#F7B055"/>
    <path d="M1.71012 12.29C1.89262 12.4818 1.99616 12.7353 2.00012 13C1.99606 13.2647 1.89253 13.5182 1.71012 13.71C1.51828 13.8924 1.26481 13.9959 1.00012 14C0.869443 13.9984 0.740343 13.9712 0.620121 13.92C0.497778 13.8716 0.385781 13.8003 0.29012 13.71C-0.0873319 13.3119 -0.0873319 12.6881 0.29012 12.29C0.481754 12.1073 0.735354 12.0037 1.00012 12C1.06717 12.0021 1.13399 12.0088 1.20012 12.02C1.26278 12.0309 1.32346 12.0511 1.38012 12.08C1.44384 12.1019 1.50436 12.1322 1.56012 12.17L1.71012 12.29Z" fill="#F7B055"/>
    <rect x="26" y="11" width="8" height="26" rx="1" stroke="#F7B055" strokeWidth="2"/>
    <rect x="1" y="-1" width="8" height="8" rx="1" transform="matrix(1 0 0 -1 25 48)" stroke="#F7B055" strokeWidth="2"/>
  </svg>
);