import React, {FC} from 'react';
import styles from './AuthorizedModal.module.css'
import Icon from "@ant-design/icons";
import {DoneIcon} from '../../assets/icons/DoneIcon'

const AuthorizedModal: FC = () => {
  return (
    <div className={styles.modalContentBlock}>
      <div className={styles.modalContent}>
        <Icon component={DoneIcon} className={styles.content__img}/>
        <div className={styles.content__text}>
          Вы уже авторизованы
        </div>
        <div className={styles.content__text2}>
          Чтобы сменить пользователя вам необходимо выйти <br/>
          из системы. Вы будете перенаправлены в личный кабинет.
        </div>
      </div>
    </div>
  );
};

export default AuthorizedModal;