import styles from './LogWithEsia.module.css'
import { FC } from "react";
import { Button } from "antd";
import {useAppDispatch} from "../../../store";
import {getEsiaLoginLink} from "../../../store/actions/userAction";
import { esiaIcon } from "../../../assets/icons/EsiaIcon";

export const LogWithEsia: FC = () => {
  const dispatch = useAppDispatch()

  const onLoginEsia = () => {
    dispatch(getEsiaLoginLink())
  }

  return (
    <Button
      className={styles.button}
      onClick={onLoginEsia}
    >
      <img src={esiaIcon} alt='esia'/>
      <span className={styles.text}>Войти через Госуслуги</span>
    </Button>
  )
}
